// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import TextSection from 'components/units/unitPages/details/TextSection';
import WhyChooseOurCDI from 'components/cdi/WhyChooseOurCDI';
import MainServices from 'components/promptService/mainServices';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import Outdoor from 'components/units/unitPages/unit/outdoorService';

// Image
import firstBlob from 'images/blobs/Slime_Purple.webp';
import secBlob from 'images/blobs/Slime_Pink.webp';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  display: ${props => props.isDisplay};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    border-bottom: ${props => props.notPaddingTop && '1px solid #C6C8CC'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }
    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      border-bottom: ${props => props.notPaddingTop && 'unset'};
      p {
        width: ${props => props.notPaddingTop && '100%'};
      }
    }
    @media ${device.tablet} {
      padding-top: ${props => props.notPaddingTop && '0 !important'};
    }
  }

  ${props =>
    props.notPaddingTop &&
    `
    section :last-child {
      display: flex;
      flex-direction: column;
      height: fit-content;
      h2 {
        padding-bottom: 1.875rem;
        @media ${device.laptop} {
          padding-bottom: 0;
        }
      }
      p {
        padding-bottom: 1.875rem;
        :last-child {
          padding-bottom: 0.875rem;
        }
        @media ${device.laptop} {
          padding-bottom: 0;
        }
      }
      @media ${device.laptop} {
        padding-bottom: 4px;
      }
    }
  `}

  div {
    div {
      ul li {
        p {
          @media ${device.tablet} {
            width: ${props => props.largeTitle && '100% !important'};
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding: ${props =>
      props.paddingTop
        ? '1.875rem 0 0 0'
        : props.notPaddingTop && '0 0 1.875rem 0'};
    border-bottom: ${props =>
      props.borderBottom
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  ${props =>
    props.paddingTop &&
    `
    @media ${device.tablet} {
      padding-top: 0;
      border-top: unset;
    }
  `}
`;

const GridMainServices = styled.div`
  margin: 0;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    border-radius: 8px;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop) => (
  <GridContainer isDesktop={isDesktop}>
    <Part gridColumn={'2 / -2'} largeTitle>
      <FeaturedMarketing
        markdown={getMarkdown(page, 'ckmv0gbdc359l0c7189gj8c61', true)}
        breadCrumb={getMarkdown(page, 'ckmotj9d4fnl70d21158j26dz', true)}
        asset={getMarkdown(page, 'ckmv0gbdc359l0c7189gj8c61', false, true)}
        blobs={{ firstBlob, secBlob }}
        isCIGI
        containerMarginBottomDesk
        firstBlobTransformDesk="translate(12%,-20%) rotate(181deg)"
        firstBlobTransformDeskLarge="translate(12%,-20%) rotate(181deg)"
        secBlobTransformDesk="translate(15%,79%) rotate(189deg)"
        secBlobTransformDeskLarge="translate(15%,79%) rotate(189deg)"
        boxInfoTextWidthDesk="27.125rem"
        boxInfoParagraphImgTransformDesk="translate(0, 80%)"
        boxInfoParagraphImgWidth="unset"
        boxInfoParagraphImgHeight="unset"
        boxImagesJustifyContentLap="center"
        boxInfoHeightLap="12rem"
        titleWidthDesk="35rem"
        titleWidthTab="20rem"
        titleLineHeightTab
        boxContentPaddingBottomDesk="5.375rem"
      />
    </Part>
    <Part gridColumn="2 / -2" width="100%" isDisplay="flex" notPaddingTop>
      <TextSection
        markdown={getMarkdown(page, 'ckmouh4i0fu580d21e4gvhbgx', true)}
        isAbout
        isShowing
        isCDICardio
        isPatologica
        isCIGI
        isBackground
      />
      <TextSection
        markdown={getMarkdown(page, 'ckmoxnkvcqvyz0b7400iemvww', true)}
        fragments={getMarkdown(page, 'ckmoxnkvcqvyz0b7400iemvww')}
        isAbout
        isShowing
        isPatologica
        marginLeftContainerMobile="3%"
      />
    </Part>
    <Part gridColumn={'2 / -2'} largeTitle>
      <WhyChooseOurCDI
        markdown={getMarkdown(page, 'ckmouvkk8qd5d0b74xi3q5wjj', true)}
        fragments={getMarkdown(page, 'ckmouvkk8qd5d0b74xi3q5wjj')}
        isCDIBrasilia
        isPatologica
      />
    </Part>
    <GridMainServices>
      <Part gridColumn={'2 / -2'}>
        <MainServices
          markdown={getMarkdown(page, 'ckmovsvmwg2a40d21082toqo6', true)}
          isPatologica
          isCIGI
        />
      </Part>
    </GridMainServices>
    <Part gridColumn={'2 / -2'} borderBottom notPadding notLeft>
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmovzz1cg5jn0d21icglpcxk', true)}
        fragments={getMarkdown(page, 'ckmovzz1cg5jn0d21icglpcxk')}
        padding
        notTitleBold
        facilitiesForYou
        isBottom
        prontoAtendimento
        isPatologica
      />
      <PromptServiceContent
        markdown={getMarkdown(page, 'ckmow9hmoa5jy0b77x9nxepqi', true)}
        fragments={getMarkdown(page, 'ckmow9hmoa5jy0b77x9nxepqi')}
        padding
        notTitleBold
        facilitiesForYou
        isDropdownDesktop
        isPatologica
      />
    </Part>
    <Part gridColumn={'2 / -2'} paddingTop>
      <Outdoor
        pageName=" Centro de Diagnósticos"
        url="/centro-de-diagnosticos"
        AnatomyOrCIGI
        boxBottom
      />
    </Part>
  </GridContainer>
);
const IntervencaoPorImagem = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'ckmv0gbdc359l0c7189gj8c61', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckmowd6cgqnew0b749sh2fed7" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <IntervencaoPorImagem page={response.gcms.site.pages} />;
      }}
    />
  );
};
